import "react-awesome-lightbox/build/style.css";

import CropFreeIcon from "@mui/icons-material/CropFree";
import { Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import React, { useContext, useState } from "react";
import Lightbox from "react-awesome-lightbox";

import { FormattedText } from "../../../../components/FormattedText/FormattedText";
import { InspirationFooterFocus } from "../../../../components/Inspiration/components/InspirationFooter/InspirationFooterFocus";
import { InspirationImage } from "../../../../components/Inspiration/Inspiration";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { localeLink } from "../../../../helpers/localeLink";
import { InspirationImagePageContext } from "../../../../helpers/pagesContext";
import { INSPIRATIONS } from "../../../../helpers/route";
import * as styles from "./Body.module.css";

export const Body = () => {
  const [open, setOpen] = useState(false);
  const {
    inspirationImage: {
      id,
      url,
      title,
      thumbnail,
      content,
      author,
      authorPicture,
      authorJob,
    },
    resultLastInspirationImage,
    inspirationContainer: { goingBack, keepExploring, otherImages },
  } = useContext(InspirationImagePageContext);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <section className={styles.watermark}>
      <div className={styles.back}>
        <Link to={localeLink(INSPIRATIONS)}>
          <StaticImage
            src="../../../../resources/images/article/arrow.png"
            alt="arrow"
            width={33}
          />
          <span>{goingBack}</span>
        </Link>
      </div>
      <SlideTransition delay={0.4}>
        <div className={styles.root}>
          <div className={styles.container}>
            <div className={styles.image}>
              <div className={styles.imageWrap} onClick={handleOpen}>
                <GatsbyImage image={thumbnail.gatsbyImageData} alt={title} />
              </div>
              <CropFreeIcon className={styles.cropFree} onClick={handleOpen} />
            </div>
            <FormattedText content={content} />
            <InspirationFooterFocus
              author={{ name: author, picture: authorPicture, job: authorJob }}
              id={id}
              url={url}
            />
          </div>
        </div>
      </SlideTransition>
      <div className={styles.moreMedia}>
        <p>{keepExploring}</p>
        <h3>{otherImages}</h3>
      </div>
      <div className={styles.cardMedias}>
        {resultLastInspirationImage.map(
          ({
            node: { url, id, author, createdAt, title, thumbnail, time },
          }) => (
            <InspirationImage
              key={id}
              {...{
                url,
                id,
                author,
                createdAt,
                title,
                thumbnail,
                time,
                delay: 0.2,
              }}
            />
          )
        )}
      </div>
      {open && (
        <Lightbox
          image={thumbnail.file.url}
          title={title}
          onClose={handleClose}
        />
      )}
    </section>
  );
};
