// extracted by mini-css-extract-plugin
export var back = "Body-module--back--ba25c";
export var cardMedias = "Body-module--cardMedias--118fe";
export var container = "Body-module--container--569f9";
export var cropFree = "Body-module--cropFree--66734";
export var image = "Body-module--image--457d1";
export var imageWrap = "Body-module--imageWrap--21724";
export var moreMedia = "Body-module--moreMedia--7894c";
export var root = "Body-module--root--24a41";
export var watermark = "Body-module--watermark--57055";
export var watermarkWrap = "Body-module--watermarkWrap--662b1";