import { graphql } from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import { InspirationImagePageContext } from "../../helpers/pagesContext";
import { INSPIRATIONS } from "../../helpers/route";
import ImageScene from "../../scenes/Image/Image";

export default function Inspiration(props) {
  const inspirationImage = props.data.inspirationImage;
  const inspirationContainer = props.data.inspirationContainer;
  const resultLastInspirationImage =
    props.data.lastInspirationImage.edges.filter(
      (element) => element.node.title !== "dummy",
    );

  return (
    <Layout currentRoute={INSPIRATIONS} data={props.data.contentfulMenuLabel}>
      <Seo
        title={inspirationImage.title}
        image={inspirationImage.thumbnail.gatsbyImageData.images.fallback.src}
        description={inspirationImage.metaDescription}
      />
      <InspirationImagePageContext.Provider
        value={{
          inspirationImage,
          resultLastInspirationImage,
          inspirationContainer,
        }}
      >
        <ImageScene />
      </InspirationImagePageContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($url: String!, $locale: String!) {
    inspirationImage: contentfulInspirationImage(
      url: { eq: $url }
      node_locale: { eq: $locale }
    ) {
      node_locale
      url
      metaDescription
      id
      title
      cover {
        gatsbyImageData(placeholder: BLURRED)
      }
      thumbnail {
        gatsbyImageData(placeholder: BLURRED)
        file {
          url
        }
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      time
      author
      authorJob
      authorPicture {
        gatsbyImageData
      }
      creation(formatString: "DD MMM YYYY", locale: "fr")
    }
    lastInspirationImage: allContentfulInspirationImage(
      limit: 3
      sort: { fields: creation, order: DESC }
      filter: { url: { ne: $url }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          url
          id
          thumbnail {
            gatsbyImageData
          }
          author
          creation(formatString: "YYYY-MM-DD HH:mm:ss")
          title
          time
          type
        }
      }
    }
    inspirationContainer: contentfulInspirationContainer(
      node_locale: { eq: $locale }
    ) {
      otherImages
      subTitleImage
      keepExploring
      goingBack
      node_locale
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;
